<template>
  <section class="how-section py-5">
    <div class="container">
      <h3 class="mb-3 text-center font-weight-bold section-title">Como funciona</h3>
      <div class="mb-5 text-center section-intro">Está a apenas alguns passos de distância</div>

      <div class="row">
        <div class="item col-12 col-md-4">
          <div class="icon-holder">
            <img :src="require(`@/assets/images/streamline-free/write-paper-ink.svg`)" alt="">
            <div class="arrow-holder d-none d-lg-inline-block"></div>
          </div><!--//icon-holder-->
          <div class="desc p-3">
            <h5><span class="step-count me-2">1</span>Descubra as suas Necessidades</h5>
            <p>Identifique os requisitos específicos para a sua formação.</p>
          </div><!--//desc-->
        </div><!--//item-->
        <div class="item col-12 col-md-4">
          <div class="icon-holder">
            <img :src="require(`@/assets/images/streamline-free/monitor-loading-progress.svg`)" alt="">
            <div class="arrow-holder d-none d-lg-inline-block"></div>
          </div><!--//icon-holder-->
          <div class="desc p-3">
            <h5><span class="step-count me-2">2</span>Explore as nossas Opções</h5>
            <p>Consulte nosso catálogo abrangente ou solicite uma formação personalizada.</p>
          </div><!--//desc-->
        </div><!--//item-->
        <div class="item col-12 col-md-4">
          <div class="icon-holder">
            <img :src="require(`@/assets/images/streamline-free/monitor-window.svg`)"  alt="">
          </div><!--//icon-holder-->
          <div class="desc p-3">
            <h5><span class="step-count me-2">3</span>Receba a sua Proposta Personalizada</h5>
            <p>Solicite uma proposta personalizada e entre em contato conosco para dar início ao seu processo de formação.</p>
          </div><!--//desc-->
        </div><!--//item-->
      </div><!--//row-->
    </div><!--//container-->
  </section><!--//how-section-->
</template>

<script>
export default {
  name: "HowSection",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

