export default class DataService {
    constructor() {
        this.url = '';
        this.data = null;
    }

    static getInstance() {
        if (!DataService.instance) {
            DataService.instance = new DataService();
        }
        return DataService.instance;
    }

    setUrl(url) {
        this.url = url;
    }

    async fetchData() {
        try {
            const response = await fetch(this.url);
            this.data = await response.json();
            return this.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

    getData() {
        return this.data;
    }
}

// Usage:
/*const dataService = DataService.getInstance();
dataService.setUrl('http://example.com/data.json');
dataService.fetchData()
    .then(data => {
        console.log('Data:', data);
    })
    .catch(error => {
        console.error('Error:', error);
    });*/