<template>
  <footer class="footer">
 <!--   <div class="container py-5 mb-3">
      <div class="row">
        <div class="footer-col col-6 col-lg-3">
          <h4 class="col-heading">Products</h4>
          <ul class="list-unstyled">
            <li><a class="text-link" href="#">Bootstrap Themes</a></li>
            <li><a class="text-link" href="#">Design Templates</a></li>
            <li><a class="text-link" href="#">Live Demo</a></li>
            <li><a class="text-link" href="#">Showcase</a></li>

          </ul>
        </div>
        <div class="footer-col col-6 col-lg-3">
          <h4 class="col-heading">Developers</h4>
          <ul class="list-unstyled">
            <li><a class="text-link" href="#">Pricing</a></li>
            <li><a class="text-link" href="#">APIs</a></li>
            <li><a class="text-link" href="#">FAQs</a></li>
            <li><a class="text-link" href="#">Support</a></li>
          </ul>
        </div>
        <div class="footer-col col-6 col-lg-3">
          <h4 class="col-heading">Resources</h4>
          <ul class="list-unstyled">
            <li><a class="text-link" href="#">Docs</a></li>
            <li><a class="text-link" href="#">Freebies</a></li>
            <li><a class="text-link" href="#">Affiliates</a></li>
            <li><a class="text-link" href="#">Community</a></li>
          </ul>
        </div>
        <div class="footer-col col-6 col-lg-3">
          <h4 class="col-heading">CoderPro</h4>
          <ul class="list-unstyled">
            <li><a class="text-link" href="#">About Us</a></li>
            <li><a class="text-link" href="#">Contact Us</a></li>
            <li><a class="text-link" href="#">Privacy</a></li>
            <li><a class="text-link" href="#">Terms of Services</a></li>
          </ul>

        </div>
      </div>
    </div>
    <div class="container">
      <hr>
    </div>
    <div class="newsletter-section py-5">
      <div class="container text-center">
        <h4 class="mb-2">Receba a nossa newsletter</h4>
        <div class="section-intro mb-2">Registe-se para estar a par de todas as nossas soluções!</div>
        <form class="signup-form row gx-1 justify-content-center pt-3">
          <div class="col-12 col-md-auto">
            <label class="sr-only" for="semail">Email</label>
            <input type="email" id="semail" name="semail1" class="form-control semail" placeholder="O seu email por favor">
          </div>
          <div class="col-12 col-md-auto mt-2 mt-md-0">
            <button type="submit" class="btn btn-primary">Registo</button>
          </div>
        </form>
      </div>
    </div>-->
    <div class="footer-bottom text-center pb-5">
      <small class="copyright">Smart Learning  &copy; </small>

    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>