<template>
  <div class="item">
    <div class="item-inner rounded shadow-sm">
      <div class="row source-holder">
        <div class="source-profile-holder col-auto">
          <img :src="require(`@/assets/images/` + imagem)" class="source-profile" alt="">
        </div><!--//col-->
        <div class="col source-meta align-self-center ">
          <div class="name">{{ titulo}}</div>
          <div class="info"><a class="text-link" :href="link">{{ titulo }}</a></div>
        </div><!--//col-->
        <div class="source-icon"><i class="fab fa-twitter"></i></div>
      </div><!--//row-->

      <div class="quote-holder">
        <blockquote class="quote-content mb-0">
          {{ descricao }}
        </blockquote>
      </div><!--//quote-holder-->
    </div><!--//item-inner-->
  </div><!--//item-->
</template>

<script>
export default {
  name: "FormacoesRecentes",
  created() { },
  data() {
    return {};
  },
  props: {
    imagem: String,
    titulo: String,
    link: String,
    descricao: String,
  },
  methods: {},

};

</script>

<style lang="scss" scoped></style>