import InnerRounded from './../assets/json/innerRounded.json';
import FormacoesRecentes from './../assets/json/formacoesRecentes.json';
import Areas from './../assets/json/areas.json';
import Matemática from './../assets/json/Matemática.json';
import Estatística from './../assets/json/Estatística.json';
import CienciaDeDados from './../assets/json/Ciência-de-Dados.json';
import Linguas from './../assets/json/Linguas.json';
import InteligenciaArtificial from './../assets/json/Inteligencia-Artificial.json';
import TI from './../assets/json/TI.json';
import ML from './../assets/json/ML.json';
import Testes from './../assets/json/testes.json';
import DevOps from './../assets/json/DevOps.json';
export default class DataLocalJson {
    innerRounded = InnerRounded;
    formacoesRecentes = FormacoesRecentes;
    areas = Areas;
    matematica = Matemática;
    estatistica = Estatística;
    cienciaDeDados = CienciaDeDados;
    linguas = Linguas;
    inteligenciaArtificial = InteligenciaArtificial;
    ti = TI;
    ml = ML;
    devOps = DevOps;
    testes = Testes;
    constructor() {
        if (DataLocalJson.instance) {
            return DataLocalJson.instance;
        }
        DataLocalJson.instance = this;
    }

    getData(tipo) {
        switch (tipo) {
            case 'innerRounded':
                return this.innerRounded.itens;
            case 'formacoesRecentes':
                return this.formacoesRecentes.itens;
            case 'areas':
                return this.areas.itens;
            case 'Matemática':
                return this.matematica.cursos;
            case 'Estatística':
                return this.estatistica.cursos;
            case 'Ciência de Dados':
                return this.cienciaDeDados.cursos;
            case 'Línguas':
                return this.linguas.cursos;
            case 'Inteligência-Artificial':
                return this.inteligenciaArtificial.cursos;
            case 'Tecnologias de Informação':
                return this.ti.cursos;
            case 'Machine Learning':
                return this.ml.cursos;
            case 'Testes de Software':
                return this.testes.cursos;
            case 'DevOps':
                return this.devOps.cursos;
            default:
                throw new Error('Invalid service name');
        }
    }

}
