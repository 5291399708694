<template>
  <div class="container">
    <hr>
  </div>
  <section class="integration-section text-center py-5">
    <div class="container">
      <h3 class="mb-3">Algumas Tecnologias com as quais trabalhamos</h3>
      <div class="section-intro mb-3">Não encontra a tecnologia que necessita? Fale connosco!</div>
      <div class="integration-list row justify-content-center py-5">
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 top-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/docker-4.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 bottom-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/azure-2.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 top-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/google-cloud-1.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 bottom-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/aws.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 top-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/kubernets.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/tensorflow-2.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 top-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/spring-3.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 bottom-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/vue-9.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 top-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/Scikit_learn_logo_small.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5 bottom-line-lg"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/cucumber.svg`)" alt=""></div>
        <div class="item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5"><img class="shadow rounded-circle"
          :src="require(`@/assets/images/logos/selenium.svg`)" alt=""></div>
      </div><!--//row-->

      <div class="pt-3 text-center">
        <router-link class="btn btn-light" to="areas">Consulte o nosso catálogo<i class="fas fa-arrow-alt-circle-right ms-2"></i></router-link>
      </div>
    </div>
  </section><!--//intergration-section-->
</template>

<script>
export default {
  name: "IntegrationSection",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>