<template>
  <section class="benefits-section theme-bg-light py-5">

    <div class="container">
      <h3 class="mb-3 text-center font-weight-bold section-title">Valorizamos pessoas e empresas.</h3>
      <div class="mb-5 text-center section-intro">Invista num futuro de sucesso</div>
      <div class="row">
        <div class="item col-12 col-md-6 col-lg-3" v-for="item in itens" :key="item.id">
          <ItemInnerRounded v-bind="item"/>
        </div>


      </div><!--//row-->
      <div class="pt-3 text-center">
        <router-link class="btn btn-light" to="areas">Consulte o nosso catálogo<i
            class="fas fa-arrow-alt-circle-right ms-2"></i></router-link>
      </div>
    </div>

  </section><!--//benefits-section-->
</template>

<script>
import ItemInnerRounded from './Itens/ItemInnerRounded.vue';
import DataFacade from "@/services/DataFacade";
const facade = new DataFacade();

export default {
  name: "BenefitsSection",
  data() {
    return {
      itens: facade.getData('LocalData','innerRounded')
    };
  },
  components: { ItemInnerRounded }
};

</script>
