import DataService from './DataService';
import DataLocalJson from './DataLocalJson';

export default  class DataFacade {
    constructor() {
        this.service = new DataService();
        this.localData = new DataLocalJson();
    }

    getData(serviceName, tipo) {
        switch (serviceName) {
            case 'Service':
                return this.service.getData();
            case 'LocalData':
                console.log('tipo', tipo);
                return this.localData.getData(tipo);
            default:
                throw new Error('Invalid service name');
        }
    }
}

// Usage example
/*const facade = new DataFacade();
const dataFromService = facade.getData('Service');
const dataFromLocalData = facade.getData('LocalData');*/
