<template>



  <section class="cta-section text-center py-5 theme-bg-dark position-relative">
    <div class="theme-bg-shapes-right"></div>
    <div class="theme-bg-shapes-left"></div>
    <div class="container">
      <h3 class="mb-3 text-white mb-3">Não oferecemos apenas cursos, garantimos qualidade certificada!</h3>  
      <div class="section-intro text-white mb-3 single-col-max mx-auto">Garantimos uma aprendizagem de excelência para o desenvolvimento da sua equipa. Fortaleça as competências da sua empresa connosco! </div>
      <div class="pt-3 text-center">
        <router-link class="btn btn-light"
          to="areas">Consulte o catálogo<i class="fas fa-arrow-alt-circle-right ms-2"></i></router-link>
      </div>
    </div>
  </section><!--//cta-section-->
</template>

<script>
export default {
  name: "CtaSection",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>