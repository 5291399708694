<template>
  <header class="header fixed-top">
    <div class="branding">
      <div class="container-fluid position-relative">
        <nav class="navbar navbar-expand-lg">
          <div class="site-logo">
            <router-link class="navbar-brand" to="/"><img class="logo-icon me-2"
                :src="require(`@/assets/images/logo-smart-learning.png`)" alt="logo" /><span class="logo-text">Smart
                Learning<span class="text-alt"></span></span>
            </router-link>
          </div>



          <div class="collapse navbar-collapse py-3 py-lg-0" id="navigation">

            <ul class="navbar-nav ms-lg-auto">
              <li class="nav-item me-lg-4">
                <router-link class="nav-link" to="/contatos">Contactos</router-link>
              </li>

            </ul>
          </div>
        </nav>
      </div>
      <!--//container-->
    </div>
    <!--//branding-->
  </header>
  <!--//header-->
</template>

<script>
export default {
  name: "HeaderMain",
  created() { },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>
