<template>
  <div class="item-inner rounded">
    <div class="icon-holder text-center mx-auto mb-3">
      <i :class="icons"></i>
    </div><!--//icon-holder-->
    <h5 class="mb-3">{{ titulo }}</h5>
    <div>{{ descricao }}</div>
  </div><!--//item-inner-->
</template>

<script>

export default {
  name: "ItemInnerRounded",
  props: {
    icons: String,
    titulo: String,
    descricao: String,
  },
  data() {
    return {
    };
  }
};


</script>
