<template>
  <section class="logos-section theme-bg-primary py-5 text-center">
    <div class="container">
      <h3 class="mb-3 text-center">Formações Recentes</h3>
      <div class="section-intro mb-5">Junte-se às centenas de profissionais que já participaram nas nossas formações</div>

    </div><!--//container-->
  </section><!--//logo-section-->
</template>

<script>
export default {
  name: "LogoSection",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>