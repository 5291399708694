import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/js/all.min.js'
import 'tiny-slider/dist/tiny-slider.css'
import './assets/css/theme.css'
import './assets/css/atom-one-dark.min.css'
import VueTypewriterEffect from "vue-typewriter-effect";
import './assets/plugins/simplelightbox/simple-lightbox.min.css';
import './assets/plugins/gumshoe/gumshoe.polyfills.min.js';  



createApp(App).use(router).component("vue-typewriter-effect", VueTypewriterEffect).mount('#app')
